import {BaseFrontend} from "./components/core_components";
import './css/style.css'

function App() {
  return (
    <BaseFrontend />
  );
}

export default App;
